import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./footer.module.scss"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      footerBg: file(relativePath: { eq: "footer-bg.jpg" }) {
        relativePath
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <footer className={styles.footer}>
      {new Date().getFullYear()}, Built with
      {` `}
      Qlest
    </footer>
  )
}

export default Footer
